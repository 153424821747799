import React from 'react';
import { twMerge } from 'tailwind-merge';

import Card, { CardBody, CardHeader } from '../ui/Card';

const InstructionSteps = [
  'ชำระเงินผ่านการโอน',
  'ส่งหลักฐานการโอนเงิน/ชำระเงินทางไลน์',
];

const CashPaymentInstruction = ({
  header = true,
  secondary = false,
}: {
  header?: boolean;
  secondary?: boolean;
}) => {
  const tsaBankAccountNumber = '405-1-01374-9';
  const excelBankAccountNumber = '021-8-48599-5';

  return (
    <Card border={!secondary}>
      {header && <CardHeader>รายละเอียดการชำระเงิน</CardHeader>}
      <CardBody className={twMerge(secondary && 'bg-transparent p-0')}>
        <div className="text-sm">
          <div className="flex flex-col gap-6">
            <Section title="บริษัท">
              <DetailItem title="ชื่อบริษัท">
                TSA Group (Thailand) Co.,Ltd.
              </DetailItem>
              <DetailItem title="เบอร์โทร">035-246930-5</DetailItem>
            </Section>
            <Section title="ช่องทางการชำระเงิน">
              <DetailItem title="ธนาคาร">กสิกรไทย / นวนคร</DetailItem>
              <DetailItem title="ชื่อบัญชี">
                TSA Group (Thailand) Co.,Ltd.
              </DetailItem>
              <DetailItem title="เลขบัญชีธนาคาร">
                {tsaBankAccountNumber}{' '}
                <span
                  className="cursor-pointer text-primary-6 underline"
                  onClick={() => {
                    navigator.clipboard.writeText(tsaBankAccountNumber);
                  }}
                >
                  คัดลอก
                </span>
              </DetailItem>
            </Section>
            {!secondary && <hr className="border-light-divider" />}
            <Section title="บริษัท">
              <DetailItem title="ชื่อบริษัท">
                Excel Alai Yont (2002) Ltd.,Part.
              </DetailItem>
              <DetailItem title="เบอร์โทร">035-246930-5</DetailItem>
            </Section>
            <Section title="ช่องทางการชำระเงิน">
              <DetailItem title="ธนาคาร">กสิกรไทย / นวนคร</DetailItem>
              <DetailItem title="ชื่อบัญชี">
                ห้างหุ้นส่วนจำกัด เอ็กเซลอะไหล่ยนต์ (2002)
              </DetailItem>
              <DetailItem title="เลขบัญชีธนาคาร">
                {excelBankAccountNumber}{' '}
                <span
                  className="cursor-pointer text-primary-6 underline"
                  onClick={() => {
                    navigator.clipboard.writeText(excelBankAccountNumber);
                  }}
                >
                  คัดลอก
                </span>
              </DetailItem>
            </Section>
            <Section title="ขั้นตอนการชำระเงิน">
              <ul className="col-span-2 flex w-full flex-col gap-1">
                {InstructionSteps.map((step, index) => (
                  <li key={index} className="flex">
                    <div className="mr-1 shrink-0 font-bold">
                      ขั้นที่ {index + 1}:
                    </div>
                    <div className="flex-1 overflow-hidden break-words">
                      {step}
                    </div>
                  </li>
                ))}
              </ul>
            </Section>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="grid grid-cols-[minmax(80px,180px),minmax(100px,1fr)] gap-1">
      <div className="col-span-2 mb-1 font-semibold text-light-title">
        {title}
      </div>
      {children}
    </div>
  );
};

const DetailItem = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div className="font-medium text-light-secondary">{title}</div>
      <div className="overflow-hidden break-words text-light-title">
        {children}
      </div>
    </>
  );
};

export default CashPaymentInstruction;
