import { Alert, Button, Typography } from 'antd';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import CashPaymentInstruction from '../components/account/CashPaymentInstruction';
import EmptyLayout from '../components/layout/EmptyLayout';
import SearchEngineOptimization from '../components/utility/seo';
import { useCustomer } from '../hooks/use-customer';
import { CustomerPaymentType } from '../types/entities';

const { Text, Title } = Typography;

const CheckoutSuccess = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('orderId');

  const { customer } = useCustomer();
  const isCashOrder =
    customer?.metadata?.payment_type === CustomerPaymentType.CASH;

  return (
    <EmptyLayout>
      <SearchEngineOptimization title="checkout-success" />
      <div className="flex flex-col items-center">
        <StaticImage
          src="../images/circle-check.png"
          alt="circle-check"
          placeholder="tracedSVG"
          className="h-[40px] w-[40px]"
          objectFit="contain"
        />
        <div className="flex flex-col items-center gap-y-4">
          <Title
            className="mt-5 mb-4 !font-semibold !text-light-title"
            level={3}
          >
            ขอบคุณที่สั่งซื้อกับเรา
          </Title>
          <Text className="text-center !text-light-primary">
            ระบบได้รับคำสั่งซื้อของคุณแล้ว ทางทีมงานจะเริ่มดำเนินการ
            สู่ขั้นตอนต่อไป คุณสามารถตรวจสอบสถานะของคำสั่งซื้อได้ผ่าน
            หน้าคำสั่งซื้อของคุณ
          </Text>
          {isCashOrder && (
            <>
              {' '}
              <Alert
                message="ลูกค้าที่ชำระเงินผ่านการโอนผ่านธนาคารจะต้องส่งสลิปโอนเงินให้บริษัทเพื่อดำเนินการสั่งซื้อให้เสร็จสมบูรณ์"
                type="info"
                showIcon
              />
              <CashPaymentInstruction header={false} secondary />{' '}
            </>
          )}
          <div className="flex w-full flex-col items-center">
            <Link to={`/orders/${orderId}`} className="w-full">
              <Button type="primary" className="mt-6 w-full">
                ดูการสั่งซื้อ
              </Button>
            </Link>
            <Link to="/products" className="mt-4 !font-semibold">
              ดูสินค้าอื่นเพิ่มเติม
            </Link>
          </div>
        </div>
      </div>
    </EmptyLayout>
  );
};

export default CheckoutSuccess;
