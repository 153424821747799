import React from 'react';
import { twMerge } from 'tailwind-merge';

const Card = ({
  children,
  border = true,
}: {
  children: React.ReactNode;
  border?: boolean;
}) => {
  return (
    <div
      className={twMerge(
        'w-full overflow-hidden rounded-md bg-neutral-1',
        border ? 'border border-light-divider shadow-small' : 'border-0'
      )}
    >
      {children}
    </div>
  );
};

const CardHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="border-b border-light-divider bg-neutral-1 py-3 px-6 text-base font-semibold text-light-title">
      {children}
    </div>
  );
};

const CardBody = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={twMerge('bg-light-lighter p-6', className)}>{children}</div>
  );
};

export default Card;

export { CardHeader, CardBody };
