import { Customer, CustomerGroup } from '@medusajs/medusa';

export enum CustomerPaymentType {
  CREDIT = 'เครดิต',
  CASH = 'เงินสด',
}

export enum DEALER_STAFF_ROLE {
  SALE_REPRESENTATIVE = 'sales-representative',
}

export enum OrderDeliveryType {
  REGULAR = 'regular',
  SCHEDULE = 'schedule',
  EXPRESS = 'express',
}

export interface Dealer {
  id: string;
  name: string;
  customers: Customer[];
  customer_groups: CustomerGroup[];
  dealerStaffs: DealerStaff[];
}

export interface DealerStaff {
  role: DEALER_STAFF_ROLE;
  dealer: Dealer;
  customer: Customer;
}
